import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectsData } from './ProjectsData';
import './Projects.scss';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setProjects([...projectsData].reverse());
  }, [navigate]);

  return (
    <div className='projects-wrapper'>
      <h4 className='projects-title'>Our Projects</h4>
      <div className='projects-list'>
        {projects.map((project) => (
          <div key={project.id} className='project-item'>
            <div className='project-icon'>
              <img src={project.img} alt={project.title} className='project-img' />
            </div>
            <div className='works-item-content'>
              <h6 className='text-title'>{project.title}</h6>
              <div className='works-content'>
                <div className='text-item'>
                  <p className='main-text'>Location:</p>
                  <p className='secondary-text'>{project.location}</p>
                </div>
                <div className='text-item text-item--primary'>
                  <p className='main-text'>Approximate project value:</p>
                  <p className='secondary-text'>{project.value}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;