import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPenRuler, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import './Home.scss';
import { Link } from 'react-router-dom';

const Home = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [typedText, setTypedText] = useState('');
  const descriptionRef = useRef(null);
  const isAnimationStarted = useRef(false);

  const words = ['Choice.', 'Sense.', 'Standard.', 'Partner.', 'Store.', 'Glazing.'];
  const descriptionText =
    "We are a leading firm in providing quality and value to our clients by working on a customized approach to each project. This method allows us to succeed at any level of challenge.";


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) =>
        prevIndex === words.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [words.length]);

  useEffect(() => {
    const handleScroll = () => {
      const target = descriptionRef.current;
      if (!target || isAnimationStarted.current) return;

      const targetPosition = target.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (targetPosition <= windowHeight * 0.8) {
        isAnimationStarted.current = true;
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
          setTypedText((prev) => {

            if (currentIndex < descriptionText.length) {
              return prev + descriptionText[currentIndex++];
            } else {
              clearInterval(typingInterval);
              return prev;
            }
          });
        }, 70);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [descriptionText]);

  return (
    <div className="home-wrapper">
      <div className="screen">
        <div className="title-wrapper">
          <h1 className="screen-title">Your General </h1>
          <span className="word">{words[currentWordIndex]}</span>
        </div>
        <p className="screen-undertitle">
          Excellence in Glass and Window Solutions across Canada and the US
        </p>
      </div>
      <div className="perks">
        <div className="perks-item">
          <div className="icon-item">
            <FontAwesomeIcon className="perks-icon" icon={faPenRuler} />
          </div>
          <h4 className="item-title">What We Do</h4>
          <p className="item-content">
            Our commercial projects include both new construction and repairs/restorations. Occupied and fully operational job sites are never a problem. And we can also plan, manage, and build multi-phase jobs.
          </p>
        </div>
        <div className="perks-item">
          <div className="icon-item">
            <FontAwesomeIcon className="perks-icon" icon={faPeopleArrows} />
          </div>
          <h4 className="item-title">Working Together</h4>
          <p className="item-content">
            We offer an end-to-end client experience that includes seamless communication, budgeting, staffing, on-site organization, and solid, quality handiwork every time.
          </p>
        </div>
        <div className="perks-item">
          <div className="icon-item">
            <FontAwesomeIcon className="perks-icon" icon={faCircleCheck} />
          </div>
          <h4 className="item-title">Why Choose Us?</h4>
          <p className="item-content">
            We work with architects and designers to produce beautiful, functional structures. Call us today and bring our project management skills and extensive construction experience to your next project.
          </p>
        </div>
      </div>
      <div className="text-self">
        <div className="description" ref={descriptionRef}>
          <p className="description-text">{typedText}</p>
        </div>
      </div>
      <div className='services'>
        <h5 className='services-title'>
          Services
        </h5>
        <div className='services-wrapper'>
          <div className='services-item'>
            <div className="services-image-commercial">
              <h5 className="item-service-title">
                Commercial and industrial building projects
              </h5>
            </div>
            <div className="services-text">
              <p className="item-article">
                Services include, but are not limited to: aluminum entrances, windows, curtainwall, and light, heavy, and blast resistant commercial rated units.
              </p>
            </div>
          </div>
          <div className='services-item'>
            <div className="services-image-workspace">
              <h5 className="item-service-title">
                Workspace improvement
              </h5>
            </div>
            <div className="services-text">
              <p className="item-article">
                Designing comfortable workspaces using glass floors, glass staircases, glass railings, glass handrails, hinged and sliding glass doors, office glass walls, glass partitions.
              </p>
            </div>
          </div>
          <div className='services-item'>
            <div className="services-image-interior">
              <h5 className="item-service-title">
                Interior designs
              </h5>
            </div>
            <div className="services-text">
              <p className="item-article">
                Working to create a comfortable interior for your home as well as point solutions using high quality glass products.
              </p>
            </div>
          </div>
          <div className='services-item'>
            <div className="services-image-works">
              <Link to="/works" className="grid__link">
                Our Projects
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;