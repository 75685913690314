import React, { useEffect } from 'react';
import 'lightbox2/dist/css/lightbox.css';
import lightbox from 'lightbox2';

import item1 from './../assets/images/gallery/68174190254__A01F93DC-8810-4C4B-83DC-36D4A7F16A10.jpg';
import item2 from './../assets/images/gallery/IMG_0457.jpg';
import item3 from './../assets/images/gallery/IMG_0467.jpg';
import item4 from './../assets/images/gallery/IMG_4046.jpg';
import item5 from './../assets/images/gallery/IMG_4121.jpg';
import item6 from './../assets/images/gallery/IMG_4175.jpg';
import item7 from './../assets/images/gallery/IMG_4177.jpg';
import item8 from './../assets/images/gallery/IMG_4924.jpg';
import item9 from './../assets/images/gallery/photo_2023-08-28_17-19-11.jpg';
import item10 from './../assets/images/gallery/IMG_0306.jpg';
import item11 from './../assets/images/gallery/IMG_0307.jpg';
import item12 from './../assets/images/gallery/IMG_3402.jpg';
import item13 from './../assets/images/gallery/IMG_4116.jpg';
import item14 from './../assets/images/gallery/IMG_4359.jpg';

import './Workflow.scss';

const Workflow = () => {
  useEffect(() => {
    lightbox.option({
      'wrapAround': true,
      'fadeDuration': 300,
      'imageFadeDuration': 300,
      'resizeDuration': 500,
      'albumLabel': "Image %1 of %2",
    });
  }, []);

  return (
    <div className="workflow-wrapper">
      <div className="works__grid">
        <div className="grid__item">
          <a href={item1} data-lightbox="gallery" data-title="CONSTRUCTION OF NEW PROHIBISSION GASTRO HOUSE" className="grid__link">
            <img className="image" src={item1} alt="CONSTRUCTION OF NEW PROHIBISSION GASTRO HOUSE" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item2} data-lightbox="gallery" data-title="CONSTRUCTION OF NEW PROHIBISSION GASTRO HOUSE" className="grid__link">
            <img className="image" src={item2} alt="CONSTRUCTION OF NEW PROHIBISSION GASTRO HOUSE" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item3} data-lightbox="gallery" data-title="CONSTRUCTION OF EAST ROOM 50 CARROLL" className="grid__link">
            <img className="image" src={item3} alt="CONSTRUCTION OF EAST ROOM 50 CARROLL" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item4} data-lightbox="gallery" data-title="CONSTRUCTION OF EAST ROOM 50 CARROLL" className="grid__link">
            <img className="image" src={item4} alt="CONSTRUCTION OF EAST ROOM 50 CARROLL" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item5} data-lightbox="gallery" data-title="CONSTRUCTION OF EAST ROOM 50 CARROLL" className="grid__link">
            <img className="image" src={item5} alt="CONSTRUCTION OF EAST ROOM 50 CARROLL" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item6} data-lightbox="gallery" data-title="CONSTRUCTION OF NEW EAST ROOM LOCATION 507 KING EAST" className="grid__link">
            <img className="image" src={item6} alt="CONSTRUCTION OF NEW EAST ROOM LOCATION 507 KING EAST" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item7} data-lightbox="gallery" data-title="NEW CONSTRUCTION OF THE ROCKET CONDOMINIUM" className="grid__link">
            <img className="image" src={item7} alt="NEW CONSTRUCTION OF THE ROCKET CONDOMINIUM" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item8} data-lightbox="gallery" data-title="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" className="grid__link">
            <img className="image" src={item8} alt="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item9} data-lightbox="gallery" data-title="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" className="grid__link">
            <img className="image" src={item9} alt="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item10} data-lightbox="gallery" data-title="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" className="grid__link">
            <img className="image" src={item10} alt="EUROPRO 5001 YONGE CURTAIN WALL REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item11} data-lightbox="gallery" data-title="CIBC CURTAIN WALL REPLACEMENT" className="grid__link">
            <img className="image" src={item11} alt="CIBC CURTAIN WALL REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item12} data-lightbox="gallery" data-title="CIBC CURTAIN WALL REPLACEMENT" className="grid__link">
            <img className="image" src={item12} alt="CIBC CURTAIN WALL REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item13} data-lightbox="gallery" data-title="CIBC WINDOWS AND ENTRANCE REPLACEMENT" className="grid__link">
            <img className="image" src={item13} alt="CIBC WINDOWS AND ENTRANCE REPLACEMENT" />
          </a>
        </div>
        <div className="grid__item">
          <a href={item14} data-lightbox="gallery" data-title="MY TRIBUTE PLAZA DEVELOPMENT" className="grid__link">
            <img className="image" src={item14} alt="MY TRIBUTE PLAZA DEVELOPMENT" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Workflow;