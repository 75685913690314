import React from 'react';
import toronto from './../assets/images/about-main.jpg';
import item1 from './../assets/images/fast.jpg';
import item2 from './../assets/images/quality.jpg';
import item3 from './../assets/images/pro.jpg';

import './AboutUs.scss';



const AboutUs = () => {

  return (
    <>
      <div className='about-wrapper'>
        <h4 className='about-title'>
          General Glazing
        </h4>
        <div className='about-desc-wrapper'>
          <div className='about-desc-wrapper-image'>
            <img src={toronto} alt='toronto-glazing' className='about-desc-wrapper-image-pic'></img>
          </div>
          <div className='about-desc-wrapper-content'>
            <p className="article-about">
              «General Glazing» Ltd. is a corporation established in 2017 and
              located in the South Toronto. Our company was established to
              service and meet the needs of the rapidly growing GTA market.
            </p>
            <p className="article-about">
              «General Glazing» Ltd. specializes in a varies glass products
              and glazing work for any commercial or residential property
              alike.
            </p>
            <p className="article-about">
              Our goal is to fulfill the needs of every customer and that’s
              why we focus on our performance and professionalism. We serve
              our clients with a dedication to bring the highest possible
              service quality.
            </p>
          </div>
        </div>
        <div className='sections-wrapper'>
          <div className="service-item">
            <div className="item-icon">
              <img src={item1} alt="fast-service" className="about-img"></img>
            </div>
            <div className="item-text">
              <h4 className="item-title">
                Fast servise
              </h4>
              <p className="item-content">
                We are the leading firm by delivering quality and value to our clients. All our professionals have more than 5 years of legal experiences. We like what we do.
              </p>
            </div>
          </div>
          <div className="service-item">
            <div className="item-icon">
              <img src={item2} alt="fast-service" className="about-img"></img>
            </div>
            <div className="item-text">
              <h4 className="item-title">
                Quality
              </h4>
              <p className="item-content">
                Our managers are always ready to answer your questions. You can call us at the weekends and at night. Also you can visit our office for personal consultation.
              </p>
            </div>
          </div>
          <div className="service-item">
            <div className="item-icon">
              <img src={item3} alt="fast-service" className="about-img"></img>
            </div>
            <div className="item-text">
              <h4 className="item-title">
                Professionalism
              </h4>
              <p className="item-content">
                Our company works according to the principle of individual approach to every client. This method lets us to get success in problems of all levels.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;