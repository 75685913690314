import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects';
import Workflow from './pages/Workflow';
import ScrollToTop from './components/ScrollToTop';


import './index.scss';


const App = () => {
  return (
    <>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/works" element={<Projects />} />
          <Route path="/gallery" element={<Workflow />} />
        </Routes>
        <Footer />
    </>
  );
};

export default App;