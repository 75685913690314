import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import logo from './../assets/images/logo.png';
import './Header.scss';

const Header = () => {
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const navigate = useNavigate();

  // Функция открытия/закрытия бокового меню
  const toggleAside = () => {
    setIsAsideOpen((prevState) => !prevState);
  };

  // Функция закрытия меню и перехода на новую страницу
  const handleNavigate = (path) => {
    setIsAsideOpen(false); // Закрыть меню
    setTimeout(() => {
      navigate(path); // Выполнить навигацию после завершения анимации
    }, 300); // Тайм-аут должен совпадать с длительностью CSS-анимации
  };

  return (
    <>
      <header className="header">
        <div className="header-container">
          <div className="logo-wrapper">
            <Link className="logo-wrapper-link" to="/">
              <img className="logo-wrapper-img" src={logo} alt="General Glazing" />
            </Link>
          </div>
          <div className="burger-wrapper">
            <a href="tel:+14373350330" className="phone">
              <FontAwesomeIcon className="phone-icon" icon={faPhone} />
              <span className="phone-text">+1 437 335 0330</span>
            </a>
            <button className="burger-button" type="button" onClick={toggleAside}>
              <span className="burger-button-item"></span>
              <span className="burger-button-item"></span>
              <span className="burger-button-item"></span>
            </button>
          </div>
        </div>
        <aside className={`aside ${isAsideOpen ? 'open' : ''}`}>
          <div className="aside-top">
            <div className="logo-wrapper">
              <Link className="logo-wrapper-link" to="/" onClick={() => handleNavigate('/')}>
                <img className="logo-wrapper-img" src={logo} alt="General Glazing" />
              </Link>
            </div>
            <button className="x-button" type="button" onClick={toggleAside}>
              <span className="x-button-item x-item-one"></span>
              <span className="x-button-item x-item-two"></span>
            </button>
          </div>
          <nav className="aside-links">
            <ul className="aside-links-list">
              <li className="aside-links-list-item">
                <button
                  className="aside-links-list-item-link"
                  onClick={() => handleNavigate('/about')}
                >
                  About Us
                </button>
              </li>
              <li className="aside-links-list-item">
                <button
                  className="aside-links-list-item-link"
                  onClick={() => handleNavigate('/works')}
                >
                  Our Projects
                </button>
              </li>
              <li className="aside-links-list-item">
                <button
                  className="aside-links-list-item-link"
                  onClick={() => handleNavigate('/gallery')}
                >
                  Workflow
                </button>
              </li>
            </ul>
          </nav>
          <div className="aside-contact">
            <a href="mailto:sales@generalglazing.ca" className="phone">
              <FontAwesomeIcon className="phone-icon" icon={faEnvelope} />
              <span className="phone-text">sales@generalglazing.ca</span>
            </a>
            <a
              href="https://goo.gl/maps/dgjceaiJtDda2Dpp8"
              className="phone"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon className="phone-icon" icon={faLocationDot} />
              <span className="phone-text">50 Carroll St, Toronto ON M4M3G3</span>
            </a>
            <a href="tel:+14373350330" className="phone">
              <FontAwesomeIcon className="phone-icon" icon={faPhone} />
              <span className="phone-text">+1 437 335 0330</span>
            </a>
          </div>
        </aside>
      </header>
    </>
  );
};

export default Header;