import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import './Footer.scss';

const Footer = () => {


  return (
    <>
      <footer className="footer">
        <div className="footer-contacts">

          <a href="mailto:sales@generalglazing.ca" className="phone">
            <FontAwesomeIcon className="phone-icon" icon={faEnvelope} />
            <span className="phone-text">sales@generalglazing.ca</span>
          </a>

          <a
            href="https://goo.gl/maps/dgjceaiJtDda2Dpp8"
            className="phone"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon className="phone-icon" icon={faLocationDot} />
            <span className="phone-text">50 Carroll St, Toronto ON M4M3G3</span>
          </a>

          <a href="tel:+14373350330" className="phone">
            <FontAwesomeIcon className="phone-icon" icon={faPhone} />
            <span className="phone-text">+1 437 335 0330</span>
          </a>

        </div>
        <div className="footer-info">
          <p className="info-text">
            &#169; General Glazing Ltd. 2025
          </p>
          <p className="info-text">
            All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;